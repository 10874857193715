import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GenericServiceService } from '../services/generic-service.service';
import { NCDServiceService } from '../services/NCDFocal-service.service';
import { Router } from '@angular/router';
import { DistrictModel } from '../Model/DistrictModel';
import { TehsilModel } from '../Model/TehsilModel';
import { NCDModel } from '../Model/NCDModel';
import { CreateNCDModel } from '../Model/CreateNCDModel';
import { NCDDetailModel } from '../Model/NCDDetailModel';
import { AdminNCDModel } from '../Model/AdminNCDModel';
import { ExportService } from '../services/export.service';
import { ToastrService } from 'ngx-toastr';
import { CreateProvince } from '../Model/ProvinceModel';
import { ProvinceService } from '../services/province.service';
declare var $: any;

@Component({
  selector: 'app-NCD-Focal',
  templateUrl: './NCD-Focal.component.html',
  styleUrls: ['./NCD-Focal.component.css']
})
export class NCDFocalComponent implements OnInit {
  ncd: boolean = true
  districtModel: Array<DistrictModel> = new Array();
  teshilModel: Array<TehsilModel> = new Array();
  public Locality1: number;
  NCDFocalList: Array<NCDModel> = new Array();
  NCDInfo: CreateNCDModel = new CreateNCDModel();
  isAdmin: boolean = false;
  filter: boolean = null;
  showPatient: boolean = false;
  showselectedNCD: boolean = false;
  NCDDetailModel: NCDDetailModel = new NCDDetailModel();
  updateNCDModel: AdminNCDModel = new AdminNCDModel();
  @Output() headerTitle = new EventEmitter<string>();
  selectedDivisionId: number = 0;
  divisionModel: Array<CreateProvince> = new Array()
  districtdefModel: Array<any> = new Array();
  divisiondefModel: Array<any> = new Array();
  selectedDivision: any;
  role: any

  constructor(private NCDService: NCDServiceService, private division: ProvinceService, private genericService: GenericServiceService, private router: Router, private exportService: ExportService, private Toastr: ToastrService) { }

  ngOnInit() {
    this.showselectedNCD = false;
    this.headerTitle.emit("Focal Person");
    // Get District
    if (localStorage.getItem("role") != null && (localStorage.getItem("role") == 'DashboardUser' || localStorage.getItem("role") == 'Admin')) {
      this.role = localStorage.getItem("role");
      this.isAdmin = true;
      this.districtModel = [];
      this.genericService.getAllDistrictData().subscribe(data => {
        this.districtModel = data;
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          // console.log(error);
        });
      this.NCDFocalList = [];
      // Get Region
      this.NCDService.getallFocalPerson().subscribe(data => {

        this.NCDFocalList = data;
      }, error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
      this.divisionModel = [];
      this.division.getAllProvinceData().subscribe(data => {
        this.divisiondefModel = data;
        var ActiveDivisionModel = [];
        this.divisiondefModel.forEach(function (value) {
          if (value.IsActive == true) {
            ActiveDivisionModel.push(value);
          }
        },
          this.divisionModel = ActiveDivisionModel

        );
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          // console.log(error);
        });
    }
  }


  create() {
    this.NCDInfo.DistrictId = this.Locality1;
    this.NCDInfo.DivisionId = this.selectedDivisionId;
    this.NCDService.createFocalPerson(this.NCDInfo).subscribe((resp) => {
      // Store access token, UserId and Role in local storage
      $("#createNCDModal").modal("hide");
      this.Toastr.success("Focal Person created successfully!");
      this.ngOnInit();
      this.NCDInfo.Name = '';
      this.NCDInfo.Contact = '';
      // this.selectedDivision = 0;
      this.Locality1 = 0
    },
      (error) => {
        this.Toastr.error(error.error.Message);
        // console.log(error.error.Message)
      });
  }

  Update() {

    // console.log("Before: "+this.NCDDetailModel.Id);
    this.updateNCDModel.Id = this.NCDDetailModel.Id;
    this.updateNCDModel.Name = this.NCDDetailModel.Name;
    this.updateNCDModel.Contact = this.NCDDetailModel.Contact;
    this.updateNCDModel.DistrictId = this.NCDDetailModel.DistrictId;
    this.updateNCDModel.IsActive = this.NCDDetailModel.IsActive;
    // console.log(this.updateNCDModel);
    this.NCDService.UpdateFocalPerson(this.updateNCDModel).subscribe(resp => {
      $("#detailFPModal").modal("hide");
      this.Toastr.success('Focal Person Updated Successfully ');
      this.ncd=!this.ncd
      this.ngOnInit();
      // console.log(resp);
    }, error => {
      this.Toastr.error(error.error.Message);
      // console.log(error);
    });

  }

  selectDistricForNCDFocal(districtId: any) {
    this.Locality1 = parseInt(districtId);
  }

  selectedClinic(clinic: any) {
    this.NCDDetailModel = clinic;
    this.showselectedNCD = true;
  }

  toeditncd() {
    this.ncd = !this.ncd
  }
  export(reportType: number) {
    if (reportType == 1) {
      this.exportService.exportExcel(this.NCDFocalList, 'Focal-Person-Sheet');
    }
  }
  showSelectedDivision(divisionId: any) {
    this.selectedDivisionId = divisionId;
    this.genericService.getDistrictData(divisionId).subscribe(data => {
      this.districtdefModel = data;
      var ActiveDistrictModel = [];
      this.districtdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDistrictModel.push(value);

        }
      },
        this.districtModel = ActiveDistrictModel

      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RHCComponent } from './RHC/RHC.component';
import { DHQComponent } from './DHQ/DHQ.component';
import { NCDFocalComponent } from './NCD-Focal/NCD-Focal.component';
import { LoginComponent } from './login/login.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { AuthGuard } from './auth/auth.guard';
import { AllpatientsComponent } from './allpatients/allpatients.component';
import { DistrictsComponent } from './districts/districts.component';
import { FieldOfficerComponent } from './field-officer/field-officer.component';
import { THQComponent } from './thq/thq.component';
import { ProvinceComponent } from './province/province.component';
import { DashboardUsersComponent } from './dashboard-users/dashboard-users.component';
import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { MedicineComponent } from './medicine/medicine.component';
import { LabresultComponent } from './labresult/labresult.component';
import { PatientUrineTestComponent } from './patient-urine-test/patient-urine-test.component';
import { FootcareAssessmentComponent } from './footcare-assessment/footcare-assessment.component';
import { MechanicalManagementComponent } from './mechanical-management/mechanical-management.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'login', pathMatch: 'full'
  },
  {
    path: 'dashboard',
    component: AppLayoutComponent,
    children: [
      { path: '', component: DashboardComponent, pathMatch: 'full', canActivate: [AuthGuard] }
    ]
  },
  {
    path: 'RHC',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: RHCComponent, pathMatch: 'full', canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'DHQ',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: DHQComponent, pathMatch: 'full', canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'THQ',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: THQComponent, pathMatch: 'full', canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'Focal-Person',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: NCDFocalComponent, pathMatch: 'full', canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'Field-Officer',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: FieldOfficerComponent, pathMatch: 'full', canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'allpatients',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: AllpatientsComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'diagnosis',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: DiagnosisComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'province',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: ProvinceComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'districts',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: DistrictsComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'medicine',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: MedicineComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'lab-result',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: LabresultComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'patient-urine-test',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: PatientUrineTestComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'footcare-assessment',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: FootcareAssessmentComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'management-mechanical',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: MechanicalManagementComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'dashboard-users',
    component: AppLayoutComponent,
    children: [
      {
        path: '', component: DashboardUsersComponent, pathMatch: 'full'
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  { path: '**', redirectTo: 'dashboard' }];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export class UserModel{
    Name : any;
    Mobile : any;
    Email : any;
    ProvinceIds: { name: string; id: number[] }[] = [];
    DistrictId: { name: string; id: number[] }[] = [];
    //DistrictId : number;
    //DivisionId:number;
    IsActive:any;
}
export class UserViewModel{
    Name : any;
    Mobile : any;
    Email : any;
    DistrictId : number;
    DivisionId:number;
    IsActive:any;
    User:
        {
            UserName:any;
            Password:any;
        }
    
}
export class editUser{
    Name : any;
    Mobile : any;
    Email : any;
    DistrictId : any;
    DivisionId:any;
    IsActive:any
}
export class filt{
    Name : any;
    Mobile : any;
    Email : any;
    DistrictId : any;
    DivisionId:any;
}
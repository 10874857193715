import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CreateMechanicalManagement, EditMechanicalManagement } from '../Model/MechanicalManagement';
import { AllpatientsService } from '../services/allpatients.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-mechanical-management',
  templateUrl: './mechanical-management.component.html',
  styleUrls: ['./mechanical-management.component.css']
})
export class MechanicalManagementComponent implements OnInit {

  @Output() headerTitle = new EventEmitter<string>();
  CreateManagement: CreateMechanicalManagement = new CreateMechanicalManagement();
  currentSelected: any[];
  isAdmin : boolean = false;
  role:any
  ManagementData:Array<any>= new Array();
  editmodel:EditMechanicalManagement = new EditMechanicalManagement()
  
  constructor(private patientService: AllpatientsService, private Toastr: ToastrService) { }

  ngOnInit() {
    this.CreateManagement.IsActive = true;
    this.headerTitle.emit("Mechanical Management");
    this.role = localStorage.getItem("role");
    this.isAdmin = true;
    this.patientService.GetAllManagementMechanical().subscribe(data=>{
      this.ManagementData = data;
    },
    error=>{
      this.Toastr.error("Something went wrong on the server side !!!");
    });
  }

  create() {
    this.patientService.CreateMechanicalManagement(this.CreateManagement).subscribe((resp) => {
      this.Toastr.success("New data added successfully");
      this.ngOnInit();
      this.currentSelected = [];
      this.CreateManagement.ManagementMechanical='';
    },
      (error) => {
        this.Toastr.error("Something went wrong on the server side !!!");
      });
  }
  Update(){
    this.patientService.UpdateManagementMechanical(this.editmodel).subscribe(resp=>{
      this.Toastr.success('Data Updated Successfully ');
      $("#editMedicineModal").modal("hide");
      this.ngOnInit();
    },err=>{
    });

  } 

  SelectedMedicineDetail(data){
    this.editmodel = data;
  } 

}

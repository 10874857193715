export class CreateProvince{
    ProvinceId : any;
    IsActive : any;
    ProvinceName : any;
    ProvinceCode : any;
}
export class edit{
    ProvinceId : any;
    IsActive : any;
    ProvinceName : any;
    ProvinceCode : any;
    Code:any;
}
export class filt{
    ProvinceId : any;
    IsActive : any;
    ProvinceName : any;
    ProvinceCode : any;
}
import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { DashboardServiceService } from '../services/dashboard-service.service';
import { GenericServiceService } from '../services/generic-service.service';
import { Router } from '@angular/router';
import { DashboardData, GetDashboardDataDefault, GetDashboardDataByPatientStatusID, GetAllPatientsModel } from '../Model/DashboardModel';
import { DatePipe } from '@angular/common';
//  import { MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { ToastrService } from 'ngx-toastr';
import { DISABLED } from '@angular/forms/src/model';
import { style } from '@angular/animations';
import { ProvinceService } from '../services/province.service';
import { DistrictModel } from '../Model/DistrictModel';
import { CreateProvince } from '../Model/ProvinceModel';
import { DHQServiceService } from '../services/DHQ-service.service';
import { DHQ } from '../Model/DHQModel';
import { RHCServiceService } from '../services/RHC-service.service';
import { THQService } from '../services/thq.service';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  // @ViewChild('muliselect')
  // public mulObj: MultiSelectComponent;
  AppointmentsDetails: Array<any> = new Array();
  GetDashboardDataDefault: GetDashboardDataDefault = new GetDashboardDataDefault();
  dashboardData: DashboardData = new DashboardData();
  dashboardPatientsData: Array<DashboardData> = new Array();
  showPatient: boolean = false;
  showPatientDetails: boolean = false;
  selectedPatientType: string = "";
  @Output() headerTitle = new EventEmitter<string>();
  rcTargetModel: Array<any> = new Array();
  DistrictData: Array<any> = new Array();
  RHCData: Array<any> = new Array();
  DHDeptData: Array<any> = new Array();
  GenderData: Array<any> = new Array();
  dashboardPatientsDataa: any
  FilterDashboardData: GetDashboardDataDefault = new GetDashboardDataDefault();
  SelectedPatientDetails: GetAllPatientsModel = new GetAllPatientsModel();
  showRHCsMultiDropDown: boolean = false;
  InitialStartDate: Date = new Date("2023-01-01");
  InitialEndDate: Date = new Date;
  clickedshowdataofpatient: boolean = false
  GetPatientsByPatientStatus: GetDashboardDataByPatientStatusID = new GetDashboardDataByPatientStatusID();
  showpatientdata: boolean = false
  hiddendatadetails: boolean = false
  abc: any
  p: boolean = null;
  districtdefModel: Array<any> = new Array();
  districtModel: Array<DistrictModel> = new Array();
  divisionModel: Array<CreateProvince> = new Array()
  divisiondefModel: Array<any> = new Array();
  dhqdefModel: Array<any> = new Array();
  dhqModel: Array<DHQ> = new Array();
  districtid:number=0
DivisionData: any;


  constructor(private service: ProvinceService,private rhcservice:RHCServiceService, private thqservice:THQService,private dhqservice:DHQServiceService, private dashboardService: DashboardServiceService, private genericService: GenericServiceService, private router: Router, public datepipe: DatePipe, private Toastr: ToastrService) { }


  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true
  };

  public barChartLabels: any[] = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"];
  public barChartType: string = 'bar';
  public barChartLegend: boolean = true;

  //{ data: [{265450,91327.6,74847.04},{73550.46,29650.12,73007.05},{10870.72,5951.47,16417.49},{0,0,5593.1}], label: 'Aging Comparison' }
  public barChartData: any[] = [
    { data: [8, 4], label: 'Total Referred' }, { data: [20, 8], label: 'TB Positive' }
  ];
  public lineChartData: any[] = [
    { data: [107092, 307357, 437317, 436739, 395619], label: 'Total Complains' },
    { data: [5620, 47657, 66304, 85969, 92179], label: 'Pending Complains' }

  ];


  public barChartReportData: any[] = [
    { data: [30 - 60, 61840, 13007, 436739, 74847], label: 'Charges' },
    { data: [5620, 47657, 66304, 85969, 92179], label: 'Payments' }

  ];


  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }

  public chartClickedBAR(e: any): void {
    console.log(e);
  }

  public chartHoveredBAR(e: any): void {
    console.log(e);
  }

  public chartClickedPOLAR(e: any): void {
    console.log(e);
  }

  public chartHoveredPOLAR(e: any): void {
    console.log(e);
  }
  public chartColors: Array<any> = [
    { // first color
      backgroundColor: '#fff',
      borderColor: 'rgba(69, 173, 241,0.2)',
      pointBackgroundColor: 'rgba(69, 173, 241,0.2)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(225,10,24,0.2)'
    },
    { // second color
      backgroundColor: '#fff',
      borderColor: 'rgba(255, 255, 255,0.2)',
      pointBackgroundColor: 'rgba(255, 255, 255,0.2)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(255, 255, 255,0.2)'
    }];


  public lineChartColors: any[] = [
    { // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgb(66, 132, 244,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgb(244, 67, 54)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    }];



  public showChart: boolean = false;
  isAdmin: boolean = false;
  ngOnInit() {
    // this.InitialStartDate.setMonth(this.InitialStartDate.getMonth() - 24);
    this.InitialStartDate;
    // Get District
    if (localStorage.getItem("role") != null && (localStorage.getItem("role") == 'RegionalCoordinator')) {
      this.isAdmin = false;
      this.headerTitle.emit("Dashboard " + localStorage.getItem("role") + ":- " + localStorage.getItem("profileName"));
    }
    else {
      this.isAdmin = true;
      this.headerTitle.emit("Dashboard " + localStorage.getItem("role"));
      this.FilterDashboardData.StartDate = this.datepipe.transform(this.InitialStartDate, 'yyyy-MM-dd');
      this.FilterDashboardData.EndDate = this.datepipe.transform(this.InitialEndDate, 'yyyy-MM-dd');
      this.dashboardService.getDashboardData(this.FilterDashboardData).subscribe(data => {
        this.dashboardData = data;
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
        });
    }
    this.divisionModel = [];

    this.service.getAllProvinceData().subscribe(data => {
      this.divisiondefModel = data;
      var ActiveDivisionModel = [];
      this.divisiondefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDivisionModel.push(value);
        }
      },
        this.DivisionMultiData = ActiveDivisionModel

      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });

    this.dashboardService.getGenderData().subscribe(data => {
      this.GenderData = data;
      this.GendersMultiData = this.GenderData;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
    
      this. showDHQ();
      this. showTHQ();
      this. showRHC();
  }
  showSelectedProvince(selectedValue: any) {
    if (!selectedValue.value || selectedValue.value.length === 0) {
      return;
    }
    this.genericService.getDistrictDataByMultiProvince(selectedValue.value).subscribe(data => {
      this.districtdefModel = data;
      var ActiveDistrictModel = [];
      this.districtdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDistrictModel.push(value);
        }
      },
        this.DistrictsMultiData = ActiveDistrictModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
  }

  showDHQ() {
    this.dhqservice.getAllDHQ(this.districtid).subscribe(data => {
      this.dhqdefModel = data;
      var ActiveDHQModel = [];
      this.dhqdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDHQModel.push(value);
        }
      },
        this.DhqMultiData = ActiveDHQModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
  }

  showRHC() {
    this.rhcservice.getAllRHC(this.districtid).subscribe(data => {
      this.dhqdefModel = data;
      var ActiveDHQModel = [];
      this.dhqdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDHQModel.push(value);
        }
      },
        this.RHCMultiData = ActiveDHQModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }
  showTHQ() {
    this.thqservice.getAllTHQ(this.districtid).subscribe(data => {
      this.dhqdefModel = data;
      var ActiveDHQModel = [];
      this.dhqdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDHQModel.push(value);
        }
      },
        this.THQMultiData = ActiveDHQModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }
  onSubmit(form: any): void {
    this.FilterDashboardData.RHCIds = '0';
    this.FilterDashboardData.GenderIds = '0';
    this.FilterDashboardData.DistrictId = '0';
    this.FilterDashboardData.DHQIds = '0';
    this.FilterDashboardData.ProvinceId = '0';
    this.FilterDashboardData.THQIds = '0';

    document.getElementById('filterbtn').innerText = "Applied"
    document.getElementById('filterbtn').style.background = 'grey'
    document.getElementById('filterbtn').style.borderColor = 'grey'
    //document.getElementById('filterbtn')[DISABLED] = true;
    document.getElementById('filteractionbtn').innerHTML = ' <i class="fa fa-search u-mr-xsmall"></i> Applied';
    //@ts-ignore
    document.getElementById('filterbtn').disabled = true;

    if (form.value.RHCData !== undefined) {
      if (form.value.RHCData.length !== 0) {
        this.FilterDashboardData.RHCIds = form.value.RHCData.toString();

      }
    }
    if (form.value.DistrictData !== undefined) {
      if (form.value.DistrictData.length !== 0) {

        this.FilterDashboardData.DistrictId = form.value.DistrictData.toString();
      }
    }
    if (form.value.GendersData !== undefined) {
      if (form.value.GendersData.length !== 0) {
        this.FilterDashboardData.GenderIds = form.value.GendersData.toString();
      }
    }
    if (form.value.DhqData !== undefined) {
      if (form.value.DhqData.length !== 0) {
        this.FilterDashboardData.DHQIds = form.value.DhqData.toString();
      }
    }
    if (form.value.RHCData !== undefined) {
      if (form.value.RHCData.length !== 0) {
        this.FilterDashboardData.RHCIds = form.value.RHCData.toString();
      }
    }
    if (form.value.THQData !== undefined) {
      if (form.value.THQData.length !== 0) {
        this.FilterDashboardData.THQIds = form.value.THQData.toString();
      }
    }
    if (form.value.DivisionData !== undefined) {
      if (form.value.DivisionData.length !== 0) {
        this.FilterDashboardData.ProvinceId = form.value.DivisionData.toString();
      }
    }
    this.FilterDashboardData.StartDate = this.datepipe.transform(this.InitialStartDate, 'yyyy-MM-dd');
    this.FilterDashboardData.EndDate = this.datepipe.transform(this.InitialEndDate, 'yyyy-MM-dd');
    if (this.FilterDashboardData.StartDate <= this.FilterDashboardData.EndDate) {
      this.dashboardService.getDashboardData(this.FilterDashboardData).subscribe(data => {
        $("#DashBoardFilter").modal("hide");
        this.dashboardData = data;
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          // console.log(error);
        });

    }

    else if (this.FilterDashboardData.StartDate > this.FilterDashboardData.EndDate) {
      this.Toastr.error('Start date cannot be ahead of End Date');
    }

  }


  DivisionMultiData: { [key: string]: Object; }[] = [];
  public DivisionFields: Object = { text: 'ProvinceName', value: 'Id' };
  public DivisionPlaceholder: string = 'Select Province';

  DhqMultiData: { [key: string]: Object; }[] = [];
  public DhqFields: Object = { text: 'Name', value: 'Id' };
  public DhqPlaceholder: string = 'Select DHQ';

  DistrictsMultiData: { [key: string]: Object; }[] = [];
  public DistrictFields: Object = { text: 'DistrictName', value: 'Id' };
  public DistrictsPlaceholder: string = 'Select Districts';
 
  RHCMultiData: { [key: string]: Object; }[] = [];
  public RHCFields: Object = { text: 'Name', value: 'Id' };
  public RHCPlaceholder: string = 'All RHC';

  THQMultiData: { [key: string]: Object; }[] = [];
  public THQFields: Object = { text: 'Name', value: 'Id' };
  public THQPlaceholder: string = 'All THQ';

  GendersMultiData: { [key: string]: Object; }[] = [];
  public GendersFields: Object = { text: 'Gender', value: 'Id' };
  public GendersPlaceholder: string = 'All Genders';

  SelectDistrict(form) {
    this.FilterDashboardData.DistrictId = form.value.DistrictData.toString();

    this.dashboardService.getAllRHC(this.FilterDashboardData.DistrictId).subscribe(data => {
      this.RHCData = data;
      this.RHCMultiData = this.RHCData;
      this.showRHCsMultiDropDown = true;
    },
    error => {
      this.Toastr.error("Something went on the server side !!!");
    });
  }

  getDashboardStatuspatients(data) {
    this.FilterDashboardData.DiagnosisId = parseInt(data);
    this.dashboardService.getDashboardPatients(this.FilterDashboardData).subscribe(data => {
      this.showPatientDetails = true;
      this.dashboardPatientsData = data;
    },
    error => {
      this.Toastr.error("Something went on the server side !!!");
    });
  }

  selectedpatientdetail(data) {
    this.SelectedPatientDetails = data
    this.showpatientdata = true
  }

  SelectedReferral(data) {
    this.AppointmentsDetails = data.Appointments;
  }

  //Added Code 
  reset() {

    this.FilterDashboardData.RHCIds = '0';
    this.FilterDashboardData.GenderIds = '0';
    this.FilterDashboardData.DistrictId = '0';
    this.FilterDashboardData.DHQIds = '0';
    this.FilterDashboardData.ProvinceId = '0';
    this.FilterDashboardData.THQIds = '0';
    this.FilterDashboardData.DiagnosisId = '0';

    this.FilterDashboardData = new GetDashboardDataDefault()
    this.FilterDashboardData.StartDate = this.datepipe.transform(this.InitialStartDate = new Date("2020-01-01"), 'yyyy-MM-dd');
    this.FilterDashboardData.EndDate = this.datepipe.transform(this.InitialEndDate, 'yyyy-MM-dd');

    document.getElementById('filteractionbtn').innerHTML = '  <i class="fa fa-search u-mr-xsmall"></i> Filter';
    document.getElementById('filterbtn').style.backgroundColor = '#39b54a';
    document.getElementById('filterbtn').style.borderColor = '#249533';
    document.getElementById('filterbtn').innerText = 'Apply'

    //@ts-ignore
    document.getElementById('filterbtn').disabled = false;
    this.genericService.getAllDistrictData().subscribe(data => {
      this.DistrictData = data;
      var ActiveDistrictModel = [];
      this.DistrictData.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDistrictModel.push(value);
        }
      },
        this.DistrictsMultiData = ActiveDistrictModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
      this.DivisionData = [];
      this.service.getAllProvinceData().subscribe(data => {
        this.divisiondefModel = data;
        var ActiveDivisionModel = [];
        this.divisiondefModel.forEach(function (value) {
          if (value.IsActive == true) {
            ActiveDivisionModel.push(value);
          }
        },
          this.DivisionMultiData = ActiveDivisionModel
  
        );
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
        });

    this.dashboardService.getGenderData().subscribe(data => {
      this.GenderData = data;
      this.GendersMultiData = this.GenderData;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
    this.dashboardService.getAllRHC(this.FilterDashboardData.DistrictId).subscribe(data => {
      this.RHCData = data;
      this.RHCMultiData = this.RHCData;
      this.showRHCsMultiDropDown = true;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });

    this.dashboardService.getDashboardData(this.FilterDashboardData).subscribe(data => {
      this.dashboardData = data;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
  }
}
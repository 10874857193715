import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AllpatientsService } from '../services/allpatients.service';
import { CreateMedicine, EditMedicine } from '../Model/MedicineModel';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-medicine',
  templateUrl: './medicine.component.html',
  styleUrls: ['./medicine.component.css']
})
export class MedicineComponent implements OnInit {

  @Output() headerTitle = new EventEmitter<string>();
  CreateMedicine: CreateMedicine = new CreateMedicine();
  currentSelected: any[];
  isAdmin : boolean = false;
  role:any
  MedicineData:Array<any>= new Array();
  editmodel:EditMedicine=new EditMedicine()

  constructor(private patientService: AllpatientsService, private Toastr: ToastrService) { }

  ngOnInit() {
    this.CreateMedicine.IsActive = true;
    this.headerTitle.emit("Medicine");
    this.role = localStorage.getItem("role");
    this.isAdmin = true;
    this.patientService.getAllMedicine().subscribe(data=>{
      this.MedicineData = data;
    },
    error=>{
      this.Toastr.error("Something went wrong on the server side !!!");
    });
  }

  create() {
    console.log('entered')
    this.patientService.CreateMedicine(this.CreateMedicine).subscribe((resp) => {
      this.Toastr.success("Medicine added successfully");
      this.ngOnInit();
      this.currentSelected = [];
      this.CreateMedicine.MedicineName='';
    },
      (error) => {
        this.Toastr.error("Something went wrong on the server side !!!");
      });
  }
  Update(){
    this.patientService.UpdateMedicine(this.editmodel).subscribe(resp=>{
      this.Toastr.success('Medicine Updated Successfully ');
      $("#editMedicineModal").modal("hide");
      this.ngOnInit();
    },err=>{
    });

  } 

  SelectedMedicineDetail(data){
    this.editmodel = data;
  } 

}

import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http'
import { Observable,throwError as observableThrowError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AllpatientsService {

 // baseUrl:string="http://patientrefferals-api-dev.asd.org.pk";
  constructor(private _http:HttpClient,private router:Router) { }
  
  getAllPatients(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/api/Patients/GetAllPatients";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.post(FullUrl,data,requestOptions );
  }
  getAllPatientsFootwear(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/api/Patients/GetAllFootwearPatients";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.post(FullUrl,data,requestOptions );
  }
  exportPatients(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/api/Patients/ExportAllPatients";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.post(FullUrl,data,requestOptions );
  }
  exportPatientsFootwear(data):Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/api/Patients/ExportAllPatientsFootwear";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.post(FullUrl,data,requestOptions );
  }
  Update(data):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patients/UpdatePatientDetails";

    return this._http.post(FullUrl,data,requestOptions);
  }

  CreateMedicine(data):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patients/CreateMedicine";

    return this._http.post(FullUrl,data,requestOptions);
  }

  UpdateMedicine(data):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patients/UpdateMedicine";

    return this._http.post(FullUrl,data,requestOptions);
  }
  getAllMedicine():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/api/Patients/GetAllMedicines";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }

  CreateLabResult(data):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patients/CreateLabResult";

    return this._http.post(FullUrl,data,requestOptions);
  }

  UpdateLabResult(data):Observable<any>{
    
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patients/UpdateLabResult";

    return this._http.post(FullUrl,data,requestOptions);
  }
  getAllLabResults():Observable<any>{

    let FullUrl = localStorage.getItem('BaseUrl') +"/api/Patients/GetAllLabResults";
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };

    return this._http.get(FullUrl,requestOptions );
  }

  CreateTest(data):Observable<any>{
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patients/CreatePatientUrineTest";
    return this._http.post(FullUrl,data,requestOptions);
  }

  UpdateTest(data):Observable<any>{
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patients/UpdatePatientUrineTest";
    return this._http.post(FullUrl,data,requestOptions);
  }
  getAllTests():Observable<any>{
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl') +"/api/Patients/GetAllPatientUrineTest";
    return this._http.get(FullUrl,requestOptions );
  }

  CreateAssessment(data):Observable<any>{
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patients/CreateFootcareAssessment";
    return this._http.post(FullUrl,data,requestOptions);
  }

  UpdateAssessment(data):Observable<any>{
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patients/UpdateFootcareGrading";
    return this._http.post(FullUrl,data,requestOptions);
  }
  getAllAssessment():Observable<any>{
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl') +"/api/Patients/GetAllAssessmentGrading";
    return this._http.get(FullUrl,requestOptions );
  }

  CreateMechanicalManagement(data):Observable<any>{
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patients/CreateMechanicalManagement";
    return this._http.post(FullUrl,data,requestOptions);
  }

  UpdateManagementMechanical(data):Observable<any>{
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl')+"/api/Patients/UpdateManagementMechanical";
    return this._http.post(FullUrl,data,requestOptions);
  }
  GetAllManagementMechanical():Observable<any>{
    const headerDict = {
      'Access-Control-Allow-Origin':'*',
      "Authorization": "Bearer " + localStorage.getItem('access_token'),
      'Accept': 'application/json'
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };
    let FullUrl = localStorage.getItem('BaseUrl') +"/api/Patients/GetAllManagementMechanical";
    return this._http.get(FullUrl,requestOptions );
  }

}

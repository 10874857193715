import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { DiagnosisModel } from '../Model/DiagnosisModel';
import { GenericServiceService } from '../services/generic-service.service';
import { DiagnosisService } from '../services/diagnosis.service';
import { Router} from '@angular/router';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-diagnosis',
  templateUrl: './diagnosis.component.html',
  styleUrls: ['./diagnosis.component.css']
})
export class DiagnosisComponent implements OnInit {
  @Output() headerTitle = new EventEmitter<string>();
  diagnosisData: Array<DiagnosisModel>= new Array();
  isAdmin : boolean = false;
  showselectedDiagnosis: boolean = false;
  public  DiagnosisName: string;
  CreateDiagnosis: DiagnosisModel = new DiagnosisModel();
  selectedDiagnosis : DiagnosisModel = new DiagnosisModel();
  role:any

  constructor(private d_service: DiagnosisService, private genericService: GenericServiceService, private router: Router,public datepipe: DatePipe,private Toastr: ToastrService) { }

  ngOnInit() {
    this.CreateDiagnosis.IsActive = true;
    this.selectedDiagnosis.IsActive = true;
    this.headerTitle.emit("Diagnosis");
    this.role = localStorage.getItem("role");
    this.isAdmin = true;
    this.d_service.getDiagnosisData().subscribe(data=>{
      this.diagnosisData = data;
    },
    error=>{
      this.Toastr.error("Something went on the server side !!!");
    });
  }


  selectedDiagnosisDetail(data){
    this.selectedDiagnosis = data;
    this.showselectedDiagnosis = true;
  }

  createDiagnosis(){
    this.d_service.CreateNewDiagnosis(this.CreateDiagnosis).subscribe((resp)=>{
      this.Toastr.success('Diagnosis Created Successfully ');
      this.ngOnInit();
      this.CreateDiagnosis.Name='';
      },
      (error)=>{
        this.Toastr.error("Something went on the server side !!!");
      });
  }

  EditDiagnosis(){
    this.selectedDiagnosis.DiagnosisName = this.selectedDiagnosis.Diagnosis;
    this.d_service.UpdateDiagnosis(this.selectedDiagnosis).subscribe(resp=>{
      this.Toastr.success('Diagnosis Updated Successfully ');
      $("#editDiagnosisModal").modal("hide");
      this.ngOnInit();
    },error=>{
      this.Toastr.error("Something went on the server side !!!");
    });

  }

}

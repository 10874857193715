import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CreateTestModel, EditPatientTest } from '../Model/PatientUrineTest';
import { AllpatientsService } from '../services/allpatients.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-patient-urine-test',
  templateUrl: './patient-urine-test.component.html',
  styleUrls: ['./patient-urine-test.component.css']
})
export class PatientUrineTestComponent implements OnInit {

  @Output() headerTitle = new EventEmitter<string>();
  CreateTestModel: CreateTestModel = new CreateTestModel();
  currentSelected: any[];
  isAdmin : boolean = false;
  role:any
  PatientTestData:Array<any>= new Array();
  editmodel:EditPatientTest=new EditPatientTest()
  
  constructor(private patientService: AllpatientsService, private Toastr: ToastrService) { }

  ngOnInit() {
    this.CreateTestModel.IsActive = true;
    this.headerTitle.emit("Patient Urine Test");
    this.role = localStorage.getItem("role");
    this.isAdmin = true;
    this.patientService.getAllTests().subscribe(data=>{
      this.PatientTestData = data;
    },
    error=>{
      this.Toastr.error("Something went wrong on the server side !!!");
    });
  }

  create() {
    console.log('entered')
    this.patientService.CreateTest(this.CreateTestModel).subscribe((resp) => {
      this.Toastr.success("Test & Value added successfully");
      this.ngOnInit();
      this.currentSelected = [];
      this.CreateTestModel.patientUrineTest='';
    },
      (error) => {
        this.Toastr.error("Something went wrong on the server side !!!");
      });
  }
  Update(){
    this.patientService.UpdateTest(this.editmodel).subscribe(resp=>{
      this.Toastr.success('Test Updated Successfully ');
      $("#editMedicineModal").modal("hide");
      this.ngOnInit();
    },err=>{
    });

  } 

  SelectedMedicineDetail(data){
    this.editmodel = data;
  } 

}

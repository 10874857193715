import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CreateLabResult, EditLabResult } from '../Model/LabResult';
import { AllpatientsService } from '../services/allpatients.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-labresult',
  templateUrl: './labresult.component.html',
  styleUrls: ['./labresult.component.css']
})
export class LabresultComponent implements OnInit {

  @Output() headerTitle = new EventEmitter<string>();
  CreateLabResult: CreateLabResult = new CreateLabResult();
  currentSelected: any[];
  isAdmin : boolean = false;
  role:any
  LabResultData:Array<any>= new Array();
  editmodel:EditLabResult=new EditLabResult()
  
  constructor(private patientService: AllpatientsService, private Toastr: ToastrService) { }

  ngOnInit() {
    this.CreateLabResult.IsActive = true;
    this.headerTitle.emit("Lab Results");
    this.role = localStorage.getItem("role");
    this.isAdmin = true;
    this.patientService.getAllLabResults().subscribe(data=>{
      this.LabResultData = data;
    },
    error=>{
      this.Toastr.error("Something went wrong on the server side !!!");
    });
  }

  create() {
    console.log('entered')
    this.patientService.CreateLabResult(this.CreateLabResult).subscribe((resp) => {
      this.Toastr.success("Lab Result added successfully");
      this.ngOnInit();
      this.currentSelected = [];
      this.CreateLabResult.LabResultName='';
    },
      (error) => {
        this.Toastr.error("Something went wrong on the server side !!!");
      });
  }
  Update(){
    this.patientService.UpdateLabResult(this.editmodel).subscribe(resp=>{
      this.Toastr.success('Lab Result Updated Successfully ');
      $("#editMedicineModal").modal("hide");
      this.ngOnInit();
    },err=>{
    });

  } 

  SelectedMedicineDetail(data){
    this.editmodel = data;
  } 

}

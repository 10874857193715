export class ChartData {
    CreatedDate: Date;
    Referred: number;
    Positive: number;
}

export class DashboardData {
    TotalRegisteredPatients: number;
    TodayRegistered:number;
    DiabetesPatients:number;
    HypertensionPatients:number;
    DiabetesHypertensionPatients:number;
    FollowUps:number;
    TotalDistricts:number;
    TotalDHQ:number;
    TotalTHQ:number;
    TotalRHC:number;
    TotalPatients: number;
    NCPLabs: number;
    NCPClinics: number;
    VocationalTrainingAttendees: number;
    Districts: number;
    AlliedProviders: number;
    Labs: number;
    Clinics: number;
    chartData: ChartData[];
}


export class GetDashboardDataDefault {
    isFP: boolean = false;
    DistrictId: any = 0;
    StartDate: string;
    EndDate: string;
    RHCIds: any = "0";
    GenderIds: any = "0";
    DiagnosisId: any = "0";
    DHQIds:any="0";
    THQIds:any="0";
    ProvinceId:any="0";

}


export class GetDashboardDataByPatientStatusID {
    DistrictId: any
    PatientStatus: number;
    StartDate: string;
    EndDate: string;
}
export class GetAllPatientsModel{
    Address:any;
    CNIC:any;
    Contact:any;
    DHQName:any;
    DOB:any;
    Father_Husband_Name:any;
    Gender:any;
    GenderId:any;
    NCDRegistrationNumber:any;
    PatientId:any;
    PatientName:any;
    Referrals:any;
    ReferredAt:any;
    Status:any;
    StatusId:any;
}
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RHCServiceService } from '../services/RHC-service.service';
import { GenericServiceService } from '../services/generic-service.service';
import { Router } from '@angular/router';
import { UpdateRHC, RHCModel } from '../Model/RHCModel';
import { filt, DistrictModel } from '../Model/DistrictModel';
import { ExportService } from '../services/export.service';
import { OrderModule } from 'ngx-order-pipe';
import { CreateProvince } from '../Model/ProvinceModel';
import { DHQ } from '../Model/DHQModel';
import { DHQServiceService } from '../services/DHQ-service.service';
import { ProvinceService } from '../services/province.service';
declare var $: any;

@Component({
  selector: 'app-RHC',
  templateUrl: './RHC.component.html',
  styleUrls: ['./RHC.component.css']
})
export class RHCComponent implements OnInit {
  tr: boolean = true;
  districtModel: Array<DistrictModel> = new Array();
  ActiveDistrictModel: any
  RHCList: Array<RHCModel> = new Array();
  showselectedRHC: boolean = false;
  // selectedRHC : RHCModel = new RHCModel();
  upRHC: UpdateRHC = new UpdateRHC();
  p: boolean = null;
  Id: any
  selectedTehsil: number;
  public RHCName: string;
  public RHCPhone: string;
  public RHCAddress: string;
  public DistrictId: number;
  public Province:string;
  public District:string;
  RHCTehsil: string;
  Type: string;
  hiddendata: Array<filt> = new Array();
  isAdmin: boolean = false;
  @Output() headerTitle = new EventEmitter<string>();
  selectedProvinceId: number = 0;
  provinceModel: Array<CreateProvince> = new Array()
  provincedefModel: Array<any> = new Array();
  districtdefModel: Array<any> = new Array();
  selectedRHCDetails: boolean = false;
  dp: boolean = true
  public Locality1: number;
  showRhcdata: boolean = false;
  selectedRHC: RHCModel = new RHCModel();
  role:any
  districtid:number=0

  constructor(private province: ProvinceService, private RHCService: RHCServiceService, private DHQService: DHQServiceService, private genericService: GenericServiceService, private router: Router, private exportService: ExportService, private Toastr: ToastrService) { }

  ngOnInit() {
    this.showselectedRHC = false;
    this.headerTitle.emit("RHC");
    this.selectedTehsil = 0;

    if (localStorage.getItem("role") != null && (localStorage.getItem("role") == 'DashboardUser' || localStorage.getItem("role") == 'Admin')) {
      this.role = localStorage.getItem("role");
      this.isAdmin = true;
      this.districtModel = [];
      this.provinceModel = [];

      this.province.getAllProvinceData().subscribe(data => {
        this.provincedefModel = data;
        var ActiveProvinceModel = [];
        this.provincedefModel.forEach(function (value) {
          if (value.IsActive == true) {
            ActiveProvinceModel.push(value);
          }
        },
          this.provinceModel = ActiveProvinceModel

        ); 
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
        });

      this.RHCList = [];
      this.RHCService.getAllRHC(this.districtid).subscribe(data => {
        this.RHCList = data;
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
        });

    }
  }
  selectedLab(lab: RHCModel) {
    this.selectedRHC = lab;
    this.selectedRHCDetails = true;
    this.dp = false
  }

  showSelectedDistrictRHC(id: any) {
    if(id == 0)
      {
        id = this.selectedProvinceId;
      }
    this.DistrictId = parseInt(id);
    this.RHCList = [];
    this.RHCService.getRHCByDictrict(id).subscribe(data => {
      this.RHCList = data;
    },
      error => {
      });
  }
  createRHC() {
    this.RHCService.CreateNewRHC(this.RHCName, this.RHCPhone, this.RHCAddress, this.DistrictId).subscribe((resp) => {
      this.Toastr.success('RHC Created Successfully ');
      $("#createRHCModal").modal("hide");
      this.ngOnInit();
      this.RHCName = '';
      this.RHCAddress = '';
      this.RHCPhone = '';
      this.DistrictId = 0;
    },
      (error) => {
        this.Toastr.error("Something went on the server side !!!");
      });
  }

  export(reportType: number) {
    if (reportType == 3) {
      this.exportService.exportExcel(this.RHCList, 'RHCs');
    }
  }

  ReverseOrderOfRHC() {
    this.RHCList.sort((a, b) => (a.ReferedPatients < b.ReferedPatients) ? 1 : -1)

  }
 
  selectedRHCDetail(RHC: any,) {
    this.selectedRHC = RHC;
    this.showselectedRHC = true;
  }

  UpdateSelectedDistricForRHCCreation(data) {
    this.selectedRHC.DistrictId = parseInt(data);
  }
  EditRHC() {
    this.upRHC.RHCId = this.selectedRHC.Id;
    this.upRHC.Name = this.selectedRHC.Name;
    this.upRHC.Contact = this.selectedRHC.Contact;
    this.upRHC.DistrictId = this.selectedRHC.DistrictId;
    this.upRHC.IsActive = this.selectedRHC.IsActive;

    this.RHCService.UpdateRHC(this.upRHC).subscribe(resp => {
      this.Toastr.success('RHC Updated Successfully ');
      $("#detailRHCModal").modal("hide");
      this.tr = !this.tr
      this.ngOnInit();
    }, error => {
      this.Toastr.error("Something went on the server side !!!");
    });

  }
  openEditingField() {
    this.tr = !this.tr
  }
  showSelectedProvince(provinceId: any) {
    this.selectedProvinceId = provinceId;
    this.genericService.getDistrictData(provinceId).subscribe(data => {
      this.districtdefModel = data;
      var ActiveDistrictModel = [];
      this.districtdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDistrictModel.push(value);

        }
      },
        this.districtModel = ActiveDistrictModel

      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
        this.showSelectedDistrictRHC(this.selectedProvinceId)
  }
}

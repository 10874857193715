import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GenericServiceService } from '../services/generic-service.service';
import { FieldOfficerService } from '../services/field-officer.service';
import { Router } from '@angular/router';
import { DistrictModel } from '../Model/DistrictModel';
import { TehsilModel } from '../Model/TehsilModel';
import { NCDModel } from '../Model/NCDModel';
import { CreateFieldOfficerModel } from '../Model/CreateNCDModel';
import { NCDDetailModel } from '../Model/NCDDetailModel';
import { UpdateFieldOfficerModel } from '../Model/AdminNCDModel';
import { ExportService } from '../services/export.service';
import { ToastrService } from 'ngx-toastr';
import { CreateProvince } from '../Model/ProvinceModel';
import { ProvinceService } from '../services/province.service';
declare var $: any;

@Component({
  selector: 'app-field-officer',
  templateUrl: './field-officer.component.html',
  styleUrls: ['./field-officer.component.css']
})
export class FieldOfficerComponent implements OnInit {
  editfo: boolean = true
  districtModel: Array<DistrictModel> = new Array();
  teshilModel: Array<TehsilModel> = new Array();
  public Locality1: number;
  NCDFocalList: Array<NCDModel> = new Array();
  NCDInfo: CreateFieldOfficerModel = new CreateFieldOfficerModel();
  isAdmin: boolean = false;
  filter: boolean = null;
  showPatient: boolean = false;
  showselectedNCD: boolean = false;
  NCDDetailModel: NCDDetailModel = new NCDDetailModel();
  updateNCDModel: UpdateFieldOfficerModel = new UpdateFieldOfficerModel();
  @Output() headerTitle = new EventEmitter<string>();
  selectedDivisionId: number = 0;
  divisionModel: Array<CreateProvince> = new Array()
  districtdefModel: Array<any> = new Array();
  divisiondefModel: Array<any> = new Array();
  selectedDivision: any;
  role:any

  constructor(private FieldOfficerService: FieldOfficerService,private division:ProvinceService, private genericService: GenericServiceService, private router: Router, private exportService: ExportService, private Toastr: ToastrService) { }

  ngOnInit() {
    this.showselectedNCD = false;
    this.headerTitle.emit("Field Officer");
    // Get District
    if (localStorage.getItem("role") != null && (localStorage.getItem("role") == 'DashboardUser' || localStorage.getItem("role") == 'Admin')) {
      this.role = localStorage.getItem("role");
      this.isAdmin = true;
      this.districtModel = [];
      this.genericService.getAllDistrictData().subscribe(data => {
        this.districtModel = data;
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          // console.log(error);
        });
      this.NCDFocalList = [];
      // Get Region
      this.FieldOfficerService.getallFocalPerson().subscribe(data => {

        this.NCDFocalList = data;
      }, error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
      this.divisionModel = [];
      this.division.getAllProvinceData().subscribe(data => {
        this.divisiondefModel = data;
        var ActiveDivisionModel = [];
        this.divisiondefModel.forEach(function (value) {
          if (value.IsActive == true) {
            ActiveDivisionModel.push(value);
          }
        },
          this.divisionModel = ActiveDivisionModel

        );
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          // console.log(error);
        });
    }
  }


  create() {
    this.NCDInfo.DistrictId = this.Locality1;
    this.NCDInfo.DivisionId = this.selectedDivisionId;
    this.FieldOfficerService.createFocalPerson(this.NCDInfo).subscribe((resp) => {
      // Store access token, UserId and Role in local storage
      $("#createNCDModal").modal("hide");
      this.Toastr.success("Field Officer created successfully!");
      this.ngOnInit();
      this.NCDInfo.Name = '';
      this.NCDInfo.Contact = '';
      //this.selectedDivision = 0;
      this.Locality1=0;
    },
      (error) => {
        this.Toastr.error(error.error.Message);
        // console.log(error);
      });
  }

  Update() {

    // console.log("Before: "+this.NCDDetailModel.Id);
    this.updateNCDModel.Id = this.NCDDetailModel.Id;
    this.updateNCDModel.Name = this.NCDDetailModel.Name;
    this.updateNCDModel.Contact = this.NCDDetailModel.Contact;
    this.updateNCDModel.DistrictId = this.NCDDetailModel.DistrictId;
    this.updateNCDModel.IsActive = this.NCDDetailModel.IsActive;
    // console.log(this.updateNCDModel);
    this.FieldOfficerService.UpdateFocalPerson(this.updateNCDModel).subscribe(resp => {
      $("#detailFOModal").modal("hide");
      this.Toastr.success('Field Officer Updated Successfully ');
      this.editfo = !this.editfo
      this.ngOnInit();
      // console.log(resp);
    }, error => {
      this.Toastr.error(error.error.Message);
      // console.log(error);
    });

  }

  selectDistricForNCDFocal(districtId: any) {
    this.Locality1 = parseInt(districtId);
  }

  selectedClinic(clinic: any) {
    this.NCDDetailModel = clinic;
    this.showselectedNCD = true;
  }

  toeditfo() {
    this.editfo = !this.editfo
  }
  export(reportType: number) {
    if (reportType == 1) {
      this.exportService.exportExcel(this.NCDFocalList, 'Field-Officer-Sheet');
    }
  }
  showSelectedDivision(divisionId: any) {
    this.selectedDivisionId = divisionId;
    this.genericService.getDistrictData(divisionId).subscribe(data => {
      this.districtdefModel = data;
      var ActiveDistrictModel = [];
      this.districtdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDistrictModel.push(value);

        }
      },
        this.districtModel = ActiveDistrictModel

      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
  }
}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GenericServiceService } from '../services/generic-service.service';
import { AllpatientsService } from '../services/allpatients.service';
import { DashboardServiceService } from '../services/dashboard-service.service';
import { Router } from '@angular/router';
import { ExportService } from '../services/export.service';
import { GetAllPatientsModel, GetPatientsForReferral, GetallpatientDataDefault } from '../Model/PatientModel';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MulSheetExportService } from '../services/mul-sheet-export.service';
import { ProvinceService } from '../services/province.service';
import { RHCServiceService } from '../services/RHC-service.service';
import { THQService } from '../services/thq.service';
import { DHQServiceService } from '../services/DHQ-service.service';
import { DistrictModel } from '../Model/DistrictModel';
import { CreateProvince } from '../Model/ProvinceModel';
import { DHQ } from '../Model/DHQModel';
import { DiagnosisService } from '../services/diagnosis.service';
declare var $: any;

@Component({
  selector: 'app-allpatients',
  templateUrl: './allpatients.component.html',
  styleUrls: ['./allpatients.component.css']
})
export class AllpatientsComponent implements OnInit {
  public myModel = ''
  public mask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /[A-Z]/]
  isAdmin: boolean = false;
  showPatientDetails: boolean = false;
  filter: boolean = null;
  PatientStatus: any
  p: boolean = null;
  showRHCsMultiDropDown: boolean = false
  @Output() headerTitle = new EventEmitter<string>();
  //getallpatientsmodel : PatientModel = new PatientModel();
  getallpatientsmodel: Array<GetAllPatientsModel> = new Array();
  patientModel: any[];
  exportDataPatients: any
  SelectedPatientDetails: GetAllPatientsModel = new GetAllPatientsModel();
  filterpat: GetallpatientDataDefault = new GetallpatientDataDefault();
  RHCData: Array<any> = new Array();
  DHDeptData: Array<any> = new Array();
  GenderData: Array<any> = new Array();
  AppointmentsDetails: Array<any> = new Array();
  patientDiagnosisData: Array<any> = new Array();
  selectedDiagnosis: Array<any> = new Array()
  ShowMaskErrorMessge: boolean = false;
  MaskErrorMessage: string;
  InitialStartDate: Date = new Date("2024-01-01");
  InitialEndDate: Date = new Date;
  GenderDatas: any
  patient__Id: any
  FilterPatientsData: GetPatientsForReferral = new GetPatientsForReferral();
  DistrictData: Array<any> = new Array();
  districtdefModel: Array<any> = new Array();
  districtModel: Array<DistrictModel> = new Array();
  divisionModel: Array<CreateProvince> = new Array()
  divisiondefModel: Array<any> = new Array();
  dhqdefModel: Array<any> = new Array();
  dhqModel: Array<DHQ> = new Array();
  role: any
  ProvinceData: any;
  DhqData: any;
  THQData: any;
  districtid: number = 0

  constructor(private service: ProvinceService, private rhcservice: RHCServiceService, private thqservice: THQService, private dhqservice: DHQServiceService, private allpatientsService: AllpatientsService, private genericService: GenericServiceService, public datepipe: DatePipe, private router: Router, private exportService: ExportService, private dashboardService: DashboardServiceService, private Toastr: ToastrService, private d_service: DiagnosisService) { }

  ngOnInit() {
    this.showPatientDetails = false;
    // this.InitialStartDate.setMonth(this.InitialStartDate.getMonth() - 12);
    this.InitialStartDate;
    this.ShowMaskErrorMessge = false;
    this.headerTitle.emit("All Patients");
    // Get District
    if (localStorage.getItem("role") != null && localStorage.getItem("role") == 'DashboardUser' || localStorage.getItem("role") == 'Admin') {
      this.role = localStorage.getItem("role");
      this.isAdmin = true;
      this.getallpatientsmodel = [];
      // Get Region
      this.FilterPatientsData.StartDate = this.datepipe.transform(this.InitialStartDate, 'yyyy-MM-dd');
      this.FilterPatientsData.EndDate = this.datepipe.transform(this.InitialEndDate, 'yyyy-MM-dd');
      if (this.FilterPatientsData.StartDate <= this.FilterPatientsData.EndDate) {
        this.allpatientsService.getAllPatients(this.FilterPatientsData).subscribe(data => {
          this.getallpatientsmodel = data;
        }, error => {
          this.Toastr.error("Something went on the server side !!!");
        });
      }
      else if (this.FilterPatientsData.StartDate > this.FilterPatientsData.EndDate) {
        this.Toastr.error('Start date cannot be ahead of End Date');
      }
      this.dashboardService.getGenderData().subscribe(data => {
        this.GenderDatas = data;
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
        });
      // this.dashboardService.getGenderData().subscribe(data => {
      //   this.GenderDatas = data;
      // },
      //   error => {
      //     this.Toastr.error("Something went on the server side !!!");
      //   });
      this.genericService.getAllDistrictData().subscribe(data => {
        this.DistrictData = data;
        var ActiveDistrictModel = [];
        this.DistrictData.forEach(function (value) {
          if (value.IsActive == true) {
            ActiveDistrictModel.push(value);
          }
        },
          this.DistrictsMultiData = ActiveDistrictModel
        );
      },
        error => {
        });
    }

    this.dashboardService.getGenderData().subscribe(data => {
      this.GenderData = data;
      this.GendersMultiData = this.GenderData;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });

    this.service.getAllProvinceData().subscribe(data => {
      this.divisiondefModel = data;
      var ActiveDivisionModel = [];
      this.divisiondefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDivisionModel.push(value);
        }
      },
        this.DivisionMultiData = ActiveDivisionModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });

      this.d_service.getDiagnosisData().subscribe(data=>{
        this.patientDiagnosisData = data;
      },
      error=>{
        this.Toastr.error("Something went on the server side !!!");
      });

    this.showDHQ(this.districtid);
    this.showTHQ(this.districtid);
    this.showRHC(this.districtid);
  }
  
  showSelectedProvince(selectedValue: any) {
    if (!selectedValue.value || selectedValue.value.length === 0) {
      return;
    }
    this.genericService.getDistrictDataByMultiProvince(selectedValue.value).subscribe(data => {
      this.districtdefModel = data;
      var ActiveDistrictModel = [];
      this.districtdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDistrictModel.push(value);
        }
      },
        this.DistrictsMultiData = ActiveDistrictModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
  }
  handleChange(districtid: any) {
    if (districtid.value.length === 0) {
      this.districtid = 0;
    } else { this.districtid = districtid.value }
    this.showDHQ(this.districtid);
    this.showTHQ(this.districtid);
    this.showRHC(this.districtid);
  }

  showDHQ(districtid: any) {
    // this.DhqMultiData=[]
    this.dhqservice.getAllDHQ(districtid).subscribe(data => {
      this.dhqdefModel = data;
      var ActiveDHQModel = [];
      this.dhqdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDHQModel.push(value);
        }
      },
        this.DhqMultiData = ActiveDHQModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
  }

  showRHC(districtid: any) {
    this.rhcservice.getAllRHC(districtid).subscribe(data => {
      this.dhqdefModel = data;
      var ActiveDHQModel = [];
      this.dhqdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDHQModel.push(value);
        }
      },
        this.RHCMultiData = ActiveDHQModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
  }

  showTHQ(districtid: any) {
    this.thqservice.getAllTHQ(districtid).subscribe(data => {
      this.dhqdefModel = data;
      var ActiveDHQModel = [];
      this.dhqdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDHQModel.push(value);
        }
      },
        this.THQMultiData = ActiveDHQModel
      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
  }
  DivisionMultiData: { [key: string]: Object; }[] = [];
  public DivisionFields: Object = { text: 'ProvinceName', value: 'Id' };
  public DivisionPlaceholder: string = 'Select Province';

  DhqMultiData: { [key: string]: Object; }[] = [];
  public DhqFields: Object = { text: 'Name', value: 'Id' };
  public DhqPlaceholder: string = 'Select DHQ';

  DistrictsMultiData: { [key: string]: Object; }[] = [];
  public DistrictFields: Object = { text: 'DistrictName', value: 'Id' };
  public DistrictsPlaceholder: string = 'Select Districts';

  RHCMultiData: { [key: string]: Object; }[] = [];
  public RHCFields: Object = { text: 'Name', value: 'Id' };
  public RHCPlaceholder: string = 'All RHC';

  THQMultiData: { [key: string]: Object; }[] = [];
  public THQFields: Object = { text: 'Name', value: 'Id' };
  public THQPlaceholder: string = 'All THQ';

  DHDepartmentMultiData: { [key: string]: Object; }[] = [];
  public DHDepartmentFields: Object = { text: 'DepartmentName', value: 'Id' };
  public DHDepartmentPlaceholder: string = 'All DH Departments';

  GendersMultiData: { [key: string]: Object; }[] = [];
  public GendersFields: Object = { text: 'Gender', value: 'Id' };
  public GendersPlaceholder: string = 'All Genders';

  SelectedDistrict(form) {
    //  this.mulObj.value = [];
    this.filterpat.DistrictId = form.value.DistrictData.toString();
    this.dashboardService.getAllRHC(this.filterpat.DistrictId).subscribe(data => {
      this.RHCData = data;
      this.RHCMultiData = this.RHCData;
      this.showRHCsMultiDropDown = true;
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
  }

  SelectedPs(diagnosisId:any) {
    this.filterpat.DiagnosisId = diagnosisId;
  }

  onSubmit(form: any): void {
    this.filterpat.RHCIds = '0';

    this.filterpat.ProvinceId = '0';
    this.filterpat.DistrictId = '0';

    this.filterpat.DHQIds = '0';
    this.filterpat.THQIds = '0';

    this.filterpat.GenderIds = '0';
    this.filterpat.PatientId = '0';

    // this.filterpat.DistrictId = form.value.DistrictData.toString();
    if (form.value.RHCData !== undefined) {
      if (form.value.RHCData.length !== 0) {
        this.filterpat.RHCIds = form.value.RHCData.toString();
      }
    }
    if (form.value.DistrictData !== undefined) {
      if (form.value.DistrictData.length !== 0) {

        this.filterpat.DistrictId = form.value.DistrictData.toString();

      }
    }
    if (form.value.diagnosisData !== undefined) {
      if (form.value.diagnosisData.length !== 0) {
        this.filterpat.DiagnosisId = this.selectedDiagnosis
      }
    }
    if (form.value.GendersData !== undefined) {
      if (form.value.GendersData.length !== 0) {
        this.filterpat.GenderIds = form.value.GendersData.toString();
      }
    }
    if (form.value.DhqData !== undefined) {
      if (form.value.DhqData.length !== 0) {
        this.filterpat.DHQIds = form.value.DhqData.toString();
      }
    }
    if (form.value.RHCData !== undefined) {
      if (form.value.RHCData.length !== 0) {
        this.filterpat.RHCIds = form.value.RHCData.toString();
      }
    }
    if (form.value.THQData !== undefined) {
      if (form.value.THQData.length !== 0) {
        this.filterpat.THQIds = form.value.THQData.toString();
      }
    }
    if (form.value.ProvinceData !== undefined) {
      if (form.value.ProvinceData.length !== 0) {
        this.filterpat.ProvinceId = form.value.ProvinceData.toString();
      }
    }

    this.filterpat.StartDate = this.datepipe.transform(this.InitialStartDate, 'yyyy-MM-dd');
    this.filterpat.EndDate = this.datepipe.transform(this.InitialEndDate, 'yyyy-MM-dd');
    if (this.filterpat.StartDate <= this.filterpat.EndDate) {
      this.allpatientsService.getAllPatients(this.filterpat).subscribe(data => {

        this.getallpatientsmodel = data;
        $("#PatientsFilterModal").modal("hide");
      }, error => {
        this.Toastr.error("Something went on the server side !!!");
      });
      this.allpatientsService.exportPatients(this.filterpat).subscribe(data => {
        this.exportDataPatients = data;   
      }, error => {
        this.Toastr.error("Something went on the server side !!!");
      });
    }
    else if (this.filterpat.StartDate > this.filterpat.EndDate) {
      this.Toastr.error('Start date cannot be ahead of End Date');
    }
  }

  selectedPatient(patient: GetAllPatientsModel) {
    this.SelectedPatientDetails = patient;
    this.SelectedPatientDetails.Age //= this.datepipe.transform(this.SelectedPatientDetails.Age, 'yyyy-MM-dd');
    this.showPatientDetails = true;
  }

  getFirstNonNullName(): string {
    let name = this.SelectedPatientDetails.DHQName || this.SelectedPatientDetails.THQName || this.SelectedPatientDetails.RhcName || 'No Name Available';
    console.log(name)
    return name
  }

  selectedPatienttoEdit(patient: GetAllPatientsModel) {
    this.SelectedPatientDetails = patient;
    this.SelectedPatientDetails.Age //= this.datepipe.transform(this.SelectedPatientDetails.Age, 'yyyy-MM-dd');
  }

  SelectedGender(data) {
    this.SelectedPatientDetails.GenderId = parseInt(data);
  }

  UpdatePatient() {
    this.SelectedPatientDetails.NCDRegistrationNumber = this.SelectedPatientDetails.NCDRegistrationNumber.replace(/[/_/]/g, '');
    if (this.SelectedPatientDetails.NCDRegistrationNumber.length == 9) {
      this.ShowMaskErrorMessge = false;
      this.allpatientsService.Update(this.SelectedPatientDetails).subscribe(resp => {
        this.Toastr.success('Patient Updated Successfully ');
        $("#EditPatient").modal("hide");
        this.ngOnInit();
        // console.log(resp);
      }, err => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(err);
      });
    }
    else {
      this.ShowMaskErrorMessge = true;
      this.MaskErrorMessage = '*Please Write the Patient NCD According to Format [DD-DDDD-A]. For example:- (01-0001-A)*';
    }
  }

  SelectedReferral(data) {
    this.AppointmentsDetails = data.Appointments;
  }

  selectedPatienttoDelete(data) {
    this.patient__Id = data.PatientId
  }
  DeletePatient() {
    this.genericService.removePatient(this.patient__Id).subscribe(res => {

      $("#delete___Patient").modal("hide");
      this.Toastr.success('Successfully Deleted');

      this.allpatientsService.getAllPatients(this.filterpat).subscribe(data => {
        this.getallpatientsmodel = data;
        $("#PatientsFilterModal").modal("hide");
      }, error => {
        this.Toastr.error("Something went on the server side !!!");
      });
    }, err => {
      this.Toastr.error('Error 302');
    });
  }
  export(reportType: number) {
    if (reportType == 1) {
      this.exportService.exportExcel(this.exportDataPatients, 'AllPatientsReport');
    }
  }
}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DHQServiceService } from '../services/DHQ-service.service';
import { GenericServiceService } from '../services/generic-service.service';
import { Router } from '@angular/router';
import { DHQ, DHQ1 } from '../Model/DHQModel';
import { DistrictModel } from '../Model/DistrictModel';
import { TehsilModel } from '../Model/TehsilModel';
import { UpdateDHQModel } from '../Model/DHQDetailModel';
import { ExportService } from '../services/export.service';
import { ToastrService } from 'ngx-toastr';
import { forEach } from '@angular/router/src/utils/collection';
import { ProvinceService } from '../services/province.service';
import { CreateProvince } from '../Model/ProvinceModel';
declare var $: any;

@Component({
  selector: 'app-DHQ',
  templateUrl: './DHQ.component.html',
  styleUrls: ['./DHQ.component.css']
})
export class DHQComponent implements OnInit {

  DHQList: Array<DHQ> = new Array();
  DHQUserPatientsList: Array<DHQ> = new Array();
  districtModel: Array<DistrictModel> = new Array();
  teshilModel: Array<TehsilModel> = new Array();
  isAdmin: boolean = false;
  public DHQName: string;
  p: boolean = null
  public DHQContact: string;
  public DHQAddress: string;
  public DHQDistrict: string;
  public Locality1: number;
  selectedDistrict: number;
  showPatient: boolean = false;
  updateDHQModel: UpdateDHQModel = new UpdateDHQModel();
  @Output() headerTitle = new EventEmitter<string>();
  selectedDHQ: DHQ = new DHQ();
  DHQUserPatientsListbyref: any;
  selectedDHQDATA: DHQ1 = new DHQ1();
  DHQUser: DHQ = new DHQ();
  selectedDHQDetails: boolean = false;
  showPatientDetail: boolean = false;
  showDhqdata: boolean = false;
  dp: boolean = true
  AppointmentsDetails: Array<any> = new Array();
  apdate: boolean = false
  districtdefModel: Array<any> = new Array();
  selectedProvinceId: number = 0;
  provinceModel: Array<CreateProvince> = new Array()
  provincedefModel: Array<any> = new Array();
  showselectedDHQ: boolean = false;
  tr: boolean = true;
  role: any;
  districtid: number = 0

  constructor(private DHQService: DHQServiceService, private province: ProvinceService, private genericService: GenericServiceService, private router: Router, private exportService: ExportService, private Toastr: ToastrService) { }

  ngOnInit() {
    this.selectedDHQDetails = false;
    this.headerTitle.emit("DHQ");
    // Get District
    if (localStorage.getItem("role") != null && (localStorage.getItem("role") == 'DashboardUser' || localStorage.getItem("role") == 'Admin')) {
      this.role = localStorage.getItem("role");
      this.isAdmin = true;
      this.districtModel = [];
      this.provinceModel = [];

      this.province.getAllProvinceData().subscribe(data => {
        this.provincedefModel = data;
        var ActiveProvinceModel = [];
        this.provincedefModel.forEach(function (value) {
          if (value.IsActive == true) {
            ActiveProvinceModel.push(value);
          }
        },
          this.provinceModel = ActiveProvinceModel

        );
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          // console.log(error);
        });

      this.DHQList = [];
      this.DHQService.getAllDHQ(this.districtid).subscribe(data => {
        this.DHQList = data;
      },
        error => {
          this.Toastr.error("Something went on the server side !!!");
          // console.log(error);
        });
    }
  }

  createDHQ() {
    this.DHQService.CreateNewDHQ(this.DHQName, this.DHQContact, this.DHQAddress, this.Locality1).subscribe((resp) => {
      this.Toastr.success('DHQ Created Successfully ');
      $("#createDHQModal").modal("hide");
      this.ngOnInit();
      this.DHQName = '';
      this.DHQContact = '';
      this.DHQAddress = '';
      this.Locality1 = 0;
    },
      (err) => {
        this.Toastr.error("Something went on the server side !!!");
      });
  }


  // code added for user creation for covid department for all district 'this.DHQName,this.DHQContact,this.DHQAddress,this.Locality1
  CreateCovidDHQUsers() {
    this.DHQService.CreateCovidDHQUser().subscribe((resp) => {
      this.Toastr.success('DHQ Updated Successfully ');
      // $("#createDHQModal").modal("hide");
      this.ngOnInit();
    },
      (err) => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(err);
      });
  }

  selectDistricForDHQ(districtId: any) {
    this.Locality1 = parseInt(districtId);
  }

  showSelectedDistrictDHQ(districtId: any) {
   if(districtId == 0)
    {
      districtId = this.selectedProvinceId;
    }
    this.Locality1 = parseInt(districtId);
    this.DHQList = [];
    this.DHQService.getSelectedDistrictDHQ(districtId).subscribe(data => {
      this.DHQList = data;
      // this.DHQList = this.DHQList.slice(0,-2); 
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
  }
  selectedDHQDetail(DHQ: any,) {
    this.selectedDHQ = DHQ;
    this.showselectedDHQ = true;
  }
  selectedLab(lab: DHQ) {
    this.selectedDHQ = lab;
    this.selectedDHQDetails = true;
    this.dp = false

  }
  openEditingField() {
    this.tr = !this.tr
  }
  SelectedReferral(data) {
    this.AppointmentsDetails = data.Appointments;
    this.apdate = true
  }
  editselectedDHQUser(DHQuser: DHQ) {
    this.DHQUser = DHQuser;
  }

  SelectedDhqdata(dhqdata: DHQ1) {
    this.selectedDHQDATA = dhqdata;
    this.showDhqdata = true
    this.selectedDHQDetails = false
  }
  refreshddata() {
    $('#dataofslected').on('hidden.bs.modal',
      function () {
        $('#dataofslected form')[0].reset();
      });
  }

  selectedDHQUserPatients(DHQUserPatients) {
    this.showPatientDetail = true;
    // this.DHQUserPatientsList = [];
    this.DHQService.getSelectedDHQUserPatients(DHQUserPatients.DHQLoginId).subscribe(data => {
      this.DHQUserPatientsList = data;

    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
      });
  }

  selectDistricForUpdatingDHQ(tehsilId: any) {
    this.Locality1 = parseInt(tehsilId);
  }
  UpdateSelectedDistricForDHQCreation(data) {
    this.selectedDHQ.DistrictId = parseInt(data);
  }

  EditLab() {
    $("#editLabModal").modal("hide");
    this.updateDHQModel.DHQId = this.selectedDHQ.Id;
    this.updateDHQModel.Name = this.selectedDHQ.Name;
    this.updateDHQModel.Contact = this.selectedDHQ.Contact;
    this.updateDHQModel.DistrictId = this.selectedDHQ.DistrictId;
    this.updateDHQModel.IsActive = this.selectedDHQ.IsActive;

    this.DHQService.UpdateDHQ(this.updateDHQModel).subscribe(resp => {
      this.Toastr.success('DHQ Updated Successfully ');
      this.tr = !this.tr
      this.ngOnInit();
    }, error => {
      this.Toastr.error("Something went on the server side !!!");
    });
  }

  export(reportType: number) {
    if (reportType == 1) {
      this.exportService.exportExcel(this.DHQList, 'DHQs');
    }
    else if (reportType == 2) {
      this.exportService.exportExcel(this.DHQUserPatientsList, 'DHQUser Patients');
    }

  }
  showSelectedProvince(provinceId: any) {
    this.selectedProvinceId = provinceId;
    this.genericService.getDistrictData(provinceId).subscribe(data => {
      this.districtdefModel = data;
      var ActiveDistrictModel = [];
      this.districtdefModel.forEach(function (value) {
        if (value.IsActive == true) {
          ActiveDistrictModel.push(value);

        }
      },
        this.districtModel = ActiveDistrictModel

      );
    },
      error => {
        this.Toastr.error("Something went on the server side !!!");
        // console.log(error);
      });
      this.showSelectedDistrictDHQ(this.selectedProvinceId)
  }

}

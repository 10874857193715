export class CreateLabResult {
        LabResultName: string=''
        IsActive: boolean
    }
    
    export class EditLabResult{
        id: any
        LabResultName: string=''
        IsActive: boolean
    }
